import React, { Component } from 'react';

import "core-js/stable";

export default class Script extends Component {
    constructor(props) {
        super(props);
        if (this.props.orderShipped === "Order not shipped") {
            this.state = {
                shippingInfo: "will be shipped"
            }
        } else {
            this.state = {
                shippingInfo: `was shipped on <strong>${this.props.orderShipped}</strong>`
            }
        }
    }

    createMarkup = () => {
        var dynamicHtml = this.props.script;

        dynamicHtml = dynamicHtml.replace('{{customerName}}', this.props.customerName);
        dynamicHtml = dynamicHtml.replace('{{orderShipped}}', this.props.orderShipped);
        dynamicHtml = dynamicHtml.replace('{{customerEmail}}', this.props.customerEmail);
        dynamicHtml = dynamicHtml.replace('{{shippingInfo}}', this.state.shippingInfo);

        return {__html: dynamicHtml};
    }

    render() {
        return (
            <div className="container text-left mb-3">
                <p className="font-weight-bold text-left"><em>{this.props.isEmployee === true ? 'Script' : 'Status'}:</em></p>
                <div className="text-left">
                    <p dangerouslySetInnerHTML={this.createMarkup()}></p>
                </div>
            </div>
        )
    }
}
