import React, { Component } from 'react'

import { CSSTransition } from 'react-transition-group';
import '../Assets/Styles/CSS/Transitions.css'

import "core-js/stable";
import axios from 'axios';

import CustomerRewards from './CustomerRewards';

import '../Assets/Styles/@fortawesome/fontawesome-free/css/all.min.css'

export class Customer extends Component {

    state={
        rewards:[],
        rewardsLoaded: 0,
        showError: false
    }

    listRewards = () =>{
        axios.post('https://prod-37.westus.logic.azure.com:443/workflows/757d067b2368412ea327b4e6943dc1b5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=18qfXdYUpY_fxXTG-fCp-0DamxvyIueaYhohJU3wgjk', {
            accountNumber: this.props.customer.accountNumber
        })
        .then(response => {
            this.setState({rewards:[...response.data.rewards]})
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    componentDidMount(){
        this.listRewards()
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.customer.AccountNumber !== this.props.customer.AccountNumber){
            this.listRewards()
        }
    }

    rewardsLoaded = () => {
        this.setState({rewardsLoaded: (this.state.rewardsLoaded + 1)})
    }

    showError = () => {
        this.setState({showError: true});
    }

    render() {

        const { customerName, accountNumber, customerPackage } = this.props.customer;

        return (
            <div className="m-1 p-1">
                <div className="card border-none">
                    <div className="card-header">
                    <div className="row justify-content-between">
                        <div className="col-xs">
                            <p className="text-muted mt-1">Name</p>
                            <i className="mt-0 mr-2 ml-2 mb-2 fas fa-user"></i><span className="text-dark font-weight-bold">{customerName}</span>
                        </div>
                        <div className="col-xs">
                            <p className="text-muted mt-1">Account</p>
                            <i className="mt-0 mr-2 ml-2 mb-2 fas fa-id-card"></i><span className="text-dark font-weight-bold">{accountNumber}</span>
                        </div>
                        <div className="col-xs">
                            <p className="text-muted mt-1">Package</p>
                            <i className="mt-0 mr-2 ml-2 mb-2 fas fa-cogs"></i><span className="text-dark font-weight-bold">{customerPackage}</span>
                        </div>
                    </div>
                    </div>
                    <div className="card-body text-center">
                    <h4 className="text-center mt-2 mb-3 font-weight-light">
                        <i className="fas fa-gifts mr-2"></i>Current Rewards:
                    </h4>
                        <div style={{display: ((this.state.rewardsLoaded === this.state.rewards.length) && this.state.rewardsLoaded > 0 ? 'none' : 'inline')}} className="container text-center mt-4">
                            <h3>
                                Loading Reward Data
                                <span style={spinnerStyle} className="spinner-grow spinner-grow-sm " role="status" aria-hidden="true"></span>
                                <span style={spinnerStyle} className="spinner-grow spinner-grow-sm " role="status" aria-hidden="true"></span>
                                <span style={spinnerStyle} className="spinner-grow spinner-grow-sm " role="status" aria-hidden="true"></span>
                            </h3>
                        </div>
                        <div style={{display: (this.state.rewardsLoaded === this.state.rewards.length ? 'inline' : 'none')}}>
                            <CustomerRewards 
                                key={this.props.customer.accountNumber + '-' + this.props.customer.customerId} 
                                rewardsLoaded={this.rewardsLoaded} 
                                rewards={this.state.rewards} 
                                customer={this.props.customer}
                                showError={this.showError}
                            ></CustomerRewards>
                        </div>


                        <CSSTransition
                        in={(this.state.showError && this.state.rewardsLoaded === this.state.rewards.length)}
                        enter={(this.state.showError && this.state.rewardsLoaded === this.state.rewards.length)}
                        unmountOnExit
                        classNames="fade"
                        timeout={300}
                        >
                            <div className="container p-1 text-left">
                                <p className="lead">
                                    Whoops, it looks like we encountered an error when fetching additional reward status, please contact a Customer Care specialist at 855-463-9333 if you believe you may have qualified for a reward not listed here.
                                </p>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        )
    }
}

const spinnerStyle = {
    height: '4px',
    width: '4px',
    margin: '2px',
    marginBottom: '10px'
}

export default Customer

