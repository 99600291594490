import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group';

import "core-js/stable";
import '../Assets/Styles/CSS/Transitions.css'

class SignInCardBody extends Component {

    tryCode = (e) => {
        e.preventDefault();
        var codeInput = document.getElementById('input-accessCode').value;
        var trimmedCode = codeInput.trim();
        this.props.codeCheck(trimmedCode);
    }

    render() {
        return (
                <div>
                    <div className="mb-3">
                        <h6 className="h3">Login</h6>
                        <p className="text-muted mb-0">Enter your access code to continue.</p>
                    </div>
                    <form onSubmit={this.tryCode}>
                        <div className="form-group mb-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                            <label className="form-control-label">Access Code</label>
                            </div>
                            <div className="mb-2">
                            </div>
                        </div>
                        <div className="input-group input-group-merge">
                            <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fas fa-key"></i></span>
                            </div>
                            <input type="text" className="form-control text-left" id="input-accessCode" placeholder="Access Code" />
                        </div>
                        </div>
                        <div className="mt-4"><button type="submit" className="btn btn-md btn-customPrimary btn-icon">
                            <span className="btn-inner--text">Sign in</span>
                            <span className="btn-inner--icon"><i className="ml-2 fas fa-long-arrow-alt-right"></i></span>
                        </button></div>
                    </form>
                    <CSSTransition
                        in={this.props.accessInvalid}
                        timeout={300}
                        unmountOnExit
                        classNames="errorFade"
                        >   
                        <p className="text-danger mt-3 mb-0">Invalid or expired access code</p>
                    </CSSTransition>
                </div>
        )
    }
}



export default SignInCardBody;
