import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

import AgentSearch from "./AgentSearch";
import AgentCustomerList from "./AgentCustomerList";

import ViasatLogo from "../Assets/Images/Viasat_Logo.png";

export default class AgentPortal extends Component {
  state = {
    customers: [],
    invalidSearch: false,
    noResults: false,
  };

  handleSearchResults = (customerSearchResults, isError, errorType) => {
    if (isError) {
      //Error fetching data
      if (errorType === "invalidSearch") {
        this.setState({ invalidSearch: true });
      } else if (errorType === "noResults") {
        this.setState({ noResults: true });
      } else if (errorType === "invalidAccess") {
        this.props.handleLogout();
      }
    } else {
      //If data fetched, update customers
      this.setState({
        customers: [...customerSearchResults.customers],
        invalidSearch: false,
        noResults: false,
      });
    }
  };

  render() {
    return (
      <div style={wrapperStyle}>
        {/** Header */}
        <div className="row" style={headerStyle}>
          <div className="container">
            <img
              alt="Viasat"
              className="img-fluid p-3"
              style={logoStyle}
              src={ViasatLogo}
            ></img>
          </div>
        </div>
        <div className="row" style={gradientStyle}></div>

        {/** Customer Search */}
        <div className="row" style={searchStyle}>
          <div className="mx-auto container h-100 p-3 w-100 mr-0 ml-0">
            <h2 className="mt-2 text-center text-light mb-1">
              Customer Status Portal
            </h2>
            <p className="text-light lead text-center mb-2">
              <small>
                Enter the customer's email or numeric account number below to
                view current rewards status
              </small>
            </p>
            <p className="text-light text-center mt-0 mb-3">
              <em>
                <small>
                  Customers can replace a lost/stolen card at:{" "}
                  <a
                    target="blank"
                    style={linkStyle}
                    href="https://www.myprepaidcenter.com"
                  >
                    https://www.myprepaidcenter.com
                  </a>
                </small>
              </em>
            </p>
            <AgentSearch
              handleSearchResults={this.handleSearchResults}
              accessCode={this.props.accessCode}
            ></AgentSearch>
            <CSSTransition
              in={this.state.noResults}
              timeout={300}
              unmountOnExit
              classNames="fade"
            >
              <p className="text-danger text-center mt-1 mb-0">
                No Customers Found
              </p>
            </CSSTransition>
            <CSSTransition
              in={this.state.invalidSearch}
              timeout={300}
              unmountOnExit
              classNames="fade"
            >
              <p className="text-danger text-center mt-1 mb-0">
                Please enter a valid email address or numeric account number
              </p>
            </CSSTransition>
          </div>
        </div>

        {/** Customer Container */}
        <div style={customerContainer} className="row">
          <div className="container mt-3">
            {/** Customer List */}
            <CSSTransition
              in={!this.state.noResults}
              enter={!this.state.noResults}
              unmountOnExit
              classNames="fade"
              timeout={300}
            >
              <AgentCustomerList
                customers={this.state.customers}
              ></AgentCustomerList>
            </CSSTransition>

            {/** Customer Not Found Error */}
            <CSSTransition
              in={this.state.noResults}
              enter={this.state.noResults}
              unmountOnExit
              classNames="fade"
              timeout={500}
            >
              <div className="card p-2">
                <div className="card-body">
                  <div className="text-left">
                    <p className="lead font-weight-bold">Script: </p>
                    <p className="lead">
                      It doesn't look like you are currently qualified for any
                      rewards.
                    </p>
                    <p className="lead">
                      If you believe this is an error and you have met all
                      eligibility requirements for a reward, we are happy to
                      help you troubleshoot.
                    </p>
                    <hr></hr>
                    <p className="lead">
                      <span className="text-danger font-weight-bold">
                        NOTE:{" "}
                      </span>
                      Agent needs to make sure the customer is in the correct
                      beams, has a $0 balance and is on a qualifying plan. If
                      so, agent will have to submit a ticket to the Customer
                      Comms team to investigate.
                    </p>
                    <p className="lead">Please allow 2 days for a response.</p>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    );
  }
}
const wrapperStyle = {
  height: "100%",
  backgroundColor: "#fafbff",
  minHeight: "100vh",
};

const headerStyle = {
  backgroundColor: "white",
};

const logoStyle = {
  width: "150px",
};

const searchStyle = {
  backgroundColor: "#202e39",
};

const customerContainer = {
  height: "100%",
  borderRadius: "3px",
};

const linkStyle = {
  color: "#52d6f9",
  textDecoration: "underline",
};

const gradientStyle = {
  height: "5px",
  background:
    "linear-gradient(75deg, rgba(28,55,72,1) 0%, rgba(0,147,209,1) 50%, rgba(190,215,51,1) 100%)",
};
