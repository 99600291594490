import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group';

import '../Assets/Styles/CSS/Transitions.css'

import SignInCardBody from './SignInCardBody'
import GenerateCodeCardBody from './GenerateCodeCardBody'

class SignInCard extends Component {

    
    render() {
        return (
                <div className="card shadow border-0">
                    <div className="card-body px-md-5 pt-5">
                        <CSSTransition
                            in={this.props.signIn}
                            timeout={300}
                            unmountOnExit
                            classNames="fadeDisappear"
                        >
                            <SignInCardBody
                                codeCheck = {this.props.codeCheck}
                                accessInvalid={this.props.accessInvalid}
                                >
                            </SignInCardBody>
                        </CSSTransition>
                        <CSSTransition
                            in={this.props.generateCode}
                            timeout={300}
                            unmountOnExit
                            classNames="fadeDisappear"
                        >
                            <GenerateCodeCardBody
                                >
                            </GenerateCodeCardBody>
                        </CSSTransition>
                    </div>
                    <CSSTransition
                        in={this.props.signIn}
                        timeout={300}
                        unmountOnExit
                        classNames="fadeDisappear"
                    >
                    <div className="card-footer px-md-5"><small>Don't have an access code?</small>
                        <small><button className="linkButton ml-1" onClick={this.props.generateCodeToggle}>Generate one</button></small>
                    </div>   
                    </CSSTransition>
                </div>
        )
    }
}

export default SignInCard;
