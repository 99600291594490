import React, { Component } from 'react'

import ProgressStep from './ProgressStep';

import '../Assets/Styles/CSS/ProgressBar.css'

export default class ProgressBar extends Component {


    render() {
        return this.props.rewardSteps.map((rewardStep) => (
            <ProgressStep maxStep={this.props.maxStep} rewardStep={rewardStep} key={rewardStep.stepId}> </ProgressStep>
        )).sort((a,b)=>a.key>b.key?1:-1)
    }
}
