import React, { Component } from 'react'

import AgentPortal from './AgentPortal'
import CustomerPortal from './CustomerPortal'

export default class Portal extends Component {
    render() {
        if(this.props.isEmployee === 1){
            return (
                <div>
                    <AgentPortal 
                        accessCode={this.props.accessCode}
                        handleLogout={this.props.handleLogout}
                    ></AgentPortal>
                </div>
            )
        }
        else if(this.props.isEmployee === 0){
            return (
                <div>
                    <CustomerPortal 
                        customerId={this.props.customerId} 
                        accessCode={this.props.accessCode}
                        handleLogout={this.props.handleLogout}
                    ></CustomerPortal>
                </div>
            )
        }
        else{
            return null
        }
    }
}
