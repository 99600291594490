import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

import "core-js/stable";
import axios from "axios";

import ViasatLogo from "../Assets/Images/Viasat_Logo_White.png";
import CustomerList from "./CustomerList";
import "../Assets/Styles/CSS/Transitions.css";

export default class CustomerPortal extends Component {
  state = {
    customers: [],
    searchAttempt: false,
  };

  listCustomers = () => {
    axios
      .post(
        "https://prod-93.westus.logic.azure.com:443/workflows/4445b624076546bd921ebcf0705c83dd/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=k69nGtp69mBmsno5O3qAN_SeDFgUuUX17jf3ZgtI0Fo",
        {
          customerId: this.props.customerId,
          accessCode: this.props.accessCode,
        }
      )
      .then((response) => {
        this.setState({
          customers: response.data.customers,
          searchAttempt: true,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500 || error.response.status === 404) {
          //No customers found
          this.setState({ searchAttempt: true });
        } else if (error.response.status === 401) {
          //Access expired
          this.props.handleLogout();
        }
      });
  };

  componentDidMount() {
    this.listCustomers();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customerId !== this.props.customerId) {
      this.listCustomers();
    }
  }

  render() {
    return (
      <div className="h-100">
        <div
          className="d-flex flex-column flex-md-row align-items-center p-2 px-md-4"
          style={{ backgroundColor: "#202e39" }}
        >
          <div className="container">
            <img
              alt="Viasat"
              className="img-fluid"
              style={{ width: "150px" }}
              src={ViasatLogo}
            ></img>
          </div>
        </div>
        <div className="row mb-3" style={gradientStyle}></div>

        <div
          style={{
            display:
              this.state.customers.length < 1 &&
              this.state.searchAttempt === false
                ? "inline"
                : "none",
          }}
          className="container text-center mt-4"
        >
          <h3>
            Loading your account information
            <span
              style={spinnerStyle}
              className="spinner-grow spinner-grow-sm "
              role="status"
              aria-hidden="true"
            ></span>
            <span
              style={spinnerStyle}
              className="spinner-grow spinner-grow-sm "
              role="status"
              aria-hidden="true"
            ></span>
            <span
              style={spinnerStyle}
              className="spinner-grow spinner-grow-sm "
              role="status"
              aria-hidden="true"
            ></span>
          </h3>
        </div>

        <div className="container h-100">
          {/** Customer Container */}
          <div style={customerContainer} className="row">
            <div className="container mt-3">
              {/** Customer List */}
              <CSSTransition
                in={
                  this.state.customers.length > 0 &&
                  this.state.searchAttempt === true
                    ? true
                    : false
                }
                enter={
                  this.state.customers.length > 0 &&
                  this.state.searchAttempt === true
                    ? true
                    : false
                }
                unmountOnExit
                classNames="fade"
                timeout={300}
              >
                <CustomerList customers={this.state.customers}></CustomerList>
              </CSSTransition>
            </div>
          </div>

          {/** Unable To Retreive Customer Error */}
          <CSSTransition
            timeout={300}
            classNames="fade"
            unmountOnExit
            in={
              this.state.customers.length < 1 &&
              this.state.searchAttempt === true
                ? true
                : false
            }
            enter={
              this.state.customers.length < 1 &&
              this.state.searchAttempt === true
                ? true
                : false
            }
          >
            <div className="container">
              <h3 className="mb-3">
                Sorry, we were unable to locate your account
              </h3>
              <p className="lead">
                It doesn't look like you have qualified for any Viasat Internet
                promotions. If you believe this is an error and that you have
                met all eligibility requirements, please contact a Customer Care
                Specialist at (855) 463-9333.
              </p>
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

const customerContainer = {
  height: "100%",
  borderRadius: "3px",
};

const gradientStyle = {
  height: "8px",
  background:
    "linear-gradient(75deg, rgba(28,55,72,1) 0%, rgba(0,147,209,1) 50%, rgba(190,215,51,1) 100%)",
};

const spinnerStyle = {
  height: "4px",
  width: "4px",
  margin: "2px",
  marginBottom: "10px",
};
