import React, { Component } from 'react'

import CustomerReward from './CustomerReward'

class CustomerRewards extends Component {
    render() {
        return this.props.rewards.map((reward) => (
            <div className="row mb-2">
                <CustomerReward 
                    rewardsLoaded={this.props.rewardsLoaded} 
                    customer={this.props.customer} 
                    reward={reward} 
                    key={reward.rewardId}
                    showError={this.props.showError}
                ></CustomerReward>
            </div>
    ))
    }
}

export default CustomerRewards;
