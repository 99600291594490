import React, { Component } from 'react';

import '../Assets/Styles/CSS/ProgressBar.css';

export default class ProgressStep extends Component {

    componentDidMount(){
        this.setActive();
    }

    formatDate = (date) => {
        if (date === null){
            return null
        }
        else {
            var localDate = new Date(date)
            return localDate.toLocaleDateString()
        }
    }

    setActive = () => {
        var activeItem =  document.getElementById(this.props.rewardStep.rewardId + '-' + this.props.maxStep);
        activeItem.classList.add('is-active');
    }

    render() {
        return (
            <li id={this.props.rewardStep.rewardId + '-' + this.props.rewardStep.stepId} style={{order: this.props.rewardStep.stepId}} className={this.props.rewardStep.stepId}><p className="topLabel">{this.props.rewardStep.stepName}</p><p><small className="text-muted mt-0"><em>{this.formatDate(this.props.rewardStep.stepQualified)}</em></small></p></li>
        )
    }
}
