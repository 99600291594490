import React, { Component } from 'react'

import ViasatLogo from '../Assets/Images/Viasat_Logo.png'
import '../Assets/Styles/@fortawesome/fontawesome-free/css/all.min.css'
import '../Assets/Styles/CSS/CustomButtons.css'
import '../Assets/Styles/CSS/loginWrapper.css'
import '../Assets/Styles/CSS/Transitions.css'

import SignInCard from './SignInCard'

export default class Login extends Component {

    state = {
        generateCode: false,
        signIn: true
    }

    generateCode = (e) =>{//Listen for generate code button click and toggle state
        e.preventDefault();
        this.styleButton(!this.state.generateCode);//Style button
        this.setState({generateCode:!this.state.generateCode, signIn:!this.state.signIn});
        if(this.props.accessInvalid){//If access is invalid remove the message
            this.props.toggleInvalidMessage();
        }
    }

    styleButton = (generateCode) =>{//Controls codeBtn styling
        var button = document.getElementById('codeBtn')
        if(generateCode === true){//Style button for sign in return
            button.classList.remove('btn-customInfo')
            button.classList.add('btn-outline-dark')
            document.getElementById('genInner').innerHTML = "Return To Sign-In"
        }
        else{//Style button for code generation
            button.classList.remove('btn-outline-dark')
            button.classList.add('btn-customInfo')
            document.getElementById('genInner').innerHTML = "Generate a Code"
        }
    }

    render() {
        return (
                <div id="dotRow" className="row mx-auto" style={{backgroundColor: '#fafbff', height: 'auto', minHeight: '100vh'}}>
                    <div className="container my-auto">
                        <div className="row m-2 mt-5">
                            <div className="col-md-6 col-sm-12 col-xs-12 text-left mb-3">
                                <div className="row m-2">
                                    <img alt="Viasat" src={ViasatLogo} className="img-fluid mb-3 mx-auto" style={logoStyle}></img>
                                </div>
                                <div className="row m-2">
                                    <h3 className="mb-4">Login to the rewards portal using the access code provided to you via email.</h3>
                                <button id="codeBtn" onClick={this.generateCode} type="button" className="btn btn-md btn-customInfo btn-icon">
                                    <span id="genInner" className="btn-inner--text">Generate a Code</span>
                                    <span className="btn-inner--icon"><i className="ml-2 fas fa-key"></i></span>
                                </button>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 text-center">
                                <SignInCard
                                    codeCheck = {this.props.codeCheck} 
                                    accessInvalid = {this.props.accessInvalid}
                                    generateCode = {this.state.generateCode}
                                    generateCodeToggle = {this.generateCode}
                                    signIn={this.state.signIn}
                                >
                                </SignInCard>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}


const logoStyle = {
    width: '150px',
    height: '60px'
}

