import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Customer from './Customer'
import '../Assets/Styles/CSS/Transitions.css'


    class CustomerList extends Component {

    render() {
        return (
                    <TransitionGroup>
                        {this.props.customers.map((customer) =>(
                            <CSSTransition
                            key={customer.accountNumber}
                            timeout={300}
                            classNames="fade"
                            unmountOnExit
                            >
                                <Customer key={customer.accountNumber} customer={customer}></Customer>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
        )
    }
}



export default CustomerList;
