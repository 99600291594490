import React, { Component } from "react";

import "core-js/stable";
import axios from "axios";

export default class AgentSearch extends Component {
  customerSearch = (e) => {
    e.preventDefault();
    document.getElementById("customerSearchBtn").disabled = true;
    document.getElementById("searchText").classList.add("d-none");
    document.getElementById("searchSpinner").classList.remove("d-none");

    //Get input and regex for type tests
    var rawInput = document.getElementById("searchData").value;
    var trimmedInput = rawInput.trim();
    var validEmail = RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
    var validAccount = RegExp("^[0-9]{9,12}$");

    if (validEmail.test(trimmedInput) || validAccount.test(trimmedInput)) {
      //If valid attempt a search
      axios
        .post(
          "https://prod-52.westus.logic.azure.com:443/workflows/0d5fb5733e9c4ac59786c1deaa27a737/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=q4SCb38EflaMPC5xOKYMFTEJFobrRt2G7URWgMIuNp0",
          {
            searchType:
              validEmail.test(trimmedInput) === true
                ? "email"
                : "accountNumber",
            accessCode: this.props.accessCode,
            searchInput: trimmedInput,
          }
        )
        .then((response) => {
          //Success
          this.props.handleSearchResults(response.data, false, null);
          document.getElementById("customerSearchBtn").disabled = false;
          document.getElementById("searchText").classList.remove("d-none");
          document.getElementById("searchSpinner").classList.add("d-none");
        })
        .catch((error) => {
          //Error
          console.log(error.response.status);
          if (error.response.status === 500 || error.response.status === 404) {
            //No customers found
            this.props.handleSearchResults(null, true, "noResults");
            document.getElementById("customerSearchBtn").disabled = false;
            document.getElementById("searchText").classList.remove("d-none");
            document.getElementById("searchSpinner").classList.add("d-none");
          } else if (error.response.status === 401) {
            //Access expired
            this.props.handleSearchResults(null, true, "invalidAccess");
          }
        });
    } else {
      //Invalid search input
      this.props.handleSearchResults(null, true, "invalidSearch");
      document.getElementById("customerSearchBtn").disabled = false;
      document.getElementById("searchText").classList.remove("d-none");
      document.getElementById("searchSpinner").classList.add("d-none");
    }
  };

  render() {
    return (
      <form
        onSubmit={this.customerSearch}
        id="form"
        className="needs-validation form-signin"
        noValidate
      >
        <div className="container mx-auto">
          <div className="row">
            <div className="col-sm mx-auto">
              <div className="form-group text-center">
                <div className="form-group mx-sm-1 mb-2">
                  <input
                    style={inputStyle}
                    type="text"
                    className=" form-control"
                    id="searchData"
                    placeholder="Email Address or Account Number *"
                    required=""
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-3 col-sm-4 col-xs-6 mx-auto">
              <button
                id="customerSearchBtn"
                value="submit"
                className="btn btn-blue btn-block"
                type="submit"
              >
                <div id="searchText">
                  Search <i className="fas fa-search ml-1 p-1"></i>
                </div>
                <div className="d-none" id="searchSpinner">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Searching...</span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const inputStyle = {
  borderRadius: "3px",
};
