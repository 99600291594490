import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

import axios from 'axios';

import './Assets/Styles/CSS/App.min.css'
import './Assets/Styles/CSS/Transitions.css'

import Login from './Components/Login';
import Portal from './Components/Portal';


class App extends Component {

  state = {
    loggedIn: false,
    accessCode: null,
    codeCreated: null,
    codeExpiresOn: null,
    accessInvalid:null,
    customerId: null,
    isEmployee: null
  }

  componentDidMount(){//When page loads

    function getParameterByName(name, url) {//Get param by param name
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const accessCode = getParameterByName('accessCode');//Fetch access code if exists
    if (accessCode !== null ){
      this.setState({accessCode: accessCode})
    }
  }

  componentDidUpdate(prevProps, prevState){//Listen for state updates

    //If Access Code set via param, grab info and set created date & expiration
    if((prevState.accessCode !== this.state.accessCode) && this.state.codeExpiresIn == null && this.state.accessCode !== null){//GoGetData
        var rawCode = this.state.accessCode;
        var trimmedCode = rawCode.trim();
        this.codeCheck(trimmedCode);
    }
  }

  codeCheck = code => {//Makes the API call to check access and logs in user if valid
    this.setState({accessInvalid: false});
    if (this.state.accessCode === null) {
      window.location.href = './?accessCode=' + code;
    }
    else{
      axios.post('https://prod-80.westus.logic.azure.com:443/workflows/fea71bf877914879bdbc3840578080d1/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=H7WwE6EcMGrDGoQ9t-_6kxnCcWTnOoBvo-Z_D7z3DGo', {
        accessCode: code
      })
      .then(response => {
        this.setState({
          accessInvalid: false,
          accessCode: code,
          codeExpiresOn: new Date(response.data.expirationDatetimeUTC).toUTCString(),
          isEmployee: (response.data.isEmployee === true ? 1 : 0),
          customerId: response.data.customerId,
          agentEmail: response.data.agentEmail,
          loggedIn: true,
          codeCreated: new Date(response.data.createdDate).toUTCString()
        })
      })
      .catch(error => {
        this.setState({
          accessInvalid: true,
          accessCode: null
        })
      });
    }
  };


  toggleInvalidMessage = () => {//Toggles invalid message visibility
    this.setState({accessInvalid: !this.state.accessInvalid})
  }

  handleLogout = () => {
    this.setState({loggedIn: false, accessInvalid: true})
  }

  render(){
    return (
      <div className="App">
        <CSSTransition
                        in={!this.state.loggedIn}
                        timeout={300}
                        unmountOnExit
                        classNames="fade"
                    >
          <Login 
            codeCheck={this.codeCheck} 
            accessExpired={this.state.accessExpired}
            accessInvalid={this.state.accessInvalid}
            toggleInvalidMessage={this.toggleInvalidMessage}
          > 
          </Login>
        </CSSTransition>
        <CSSTransition
          in={this.state.loggedIn}
          timeout={500}
          unmountOnExit
          classNames="fade"
        >
          <Portal 
            customerId={this.state.customerId} 
            isEmployee={this.state.isEmployee}
            accessCode={this.state.accessCode}
            handleLogout={this.handleLogout}
          ></Portal>
        </CSSTransition>
      </div>
    );
  }
}

export default App;
