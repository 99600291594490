import React, { Component } from 'react'

import '../Assets/Styles/@fortawesome/fontawesome-free/css/all.min.css';
import '../Assets/Styles/CSS/ProgressBar.css';

import axios from 'axios';

import CustomerRewardChild from './CustomerRewardChild';
import { CSSTransition } from 'react-transition-group';

export default class CustomerReward extends Component {
    
    state = {
        rewardStatus: {},
        rewardSteps: [],
        stepsLoaded: 0
    }

    getRewardDetails = () => {
        axios.post('https://prod-37.westus.logic.azure.com:443/workflows/2a2c581f899e437ea4cf1ecec328c1bb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RVkeuhQtPuzewtrqhC6jObahbi6uRiXkEAqkk5XwJso', {
            isEmployee: false,
            rewardId: this.props.reward.rewardId,
            accountNumber: this.props.customer.accountNumber
        })
        .then(response => {
            if((!response.data.rewardStatus) || (response.data.rewardSteps.length < 1)){
                this.props.rewardsLoaded();
                this.props.showError();
            }
            else{
                this.setState({rewardStatus: response.data.rewardStatus, rewardSteps: response.data.rewardSteps});
                this.props.rewardsLoaded();
            }
        })
        .catch(error => {
            console.log(error);
            this.props.showError();
            this.props.rewardsLoaded();
        });
    }

    componentDidMount(){
        this.getRewardDetails();
    }

    render() {
        

            return (
                <div className="row mx-auto">
                    <CSSTransition
                        in={this.state.rewardStatus !== null && this.state.rewardSteps.length > 0}
                        enter={this.state.rewardStatus !== null && this.state.rewardSteps.length > 0}
                        timeout={300}
                        unmountOnExit
                        classNames="fade"
                    >
                        <CustomerRewardChild 
                            rewardId={this.state.rewardStatus === null ? null: this.state.rewardStatus.rewardId}
                            rewardName={this.props.reward.rewardName}
                            maxStep={this.state.rewardStatus === null ? null: this.state.rewardStatus.maxStep}
                            rewardSteps={this.state.rewardSteps.length < 1 ? null: this.state.rewardSteps}
                            maxStepScript={this.state.rewardStatus === null ? null : this.state.rewardStatus.maxStepScript}
                            orderStatus={this.state.rewardStatus === null ? null : this.state.rewardStatus.orderStatus}
                            orderShipped={this.state.rewardStatus === null ? null : this.state.rewardStatus.orderShipped}
                            orderTracking={this.state.rewardStatus === null ? null : this.state.rewardStatus.orderTracking}
                            customerName={this.props.customer.customerName}
                            customerEmail={this.props.customer.customerEmail}
                        ></CustomerRewardChild>
                    </CSSTransition>
                </div>
            )
        
    }
}
