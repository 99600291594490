import React, { Component } from 'react'

import '../Assets/Styles/@fortawesome/fontawesome-free/css/all.min.css';
import '../Assets/Styles/CSS/ProgressBar.css';

import ProgressBar from './ProgressBar';
import Script from './Script';

export default class AgentRewardChild extends Component {
    
    render() {
            
        const isNumber = new RegExp('^[0-9]*$');

            return (

                <div className="row mx-auto">
                        <div>
                        <div className="container mb-0">
                                <p className="font-weight-bold text-center mb-0 lead">{this.props.rewardName}</p>
                        </div>
                        <div className="row w-100 mx-auto">
                            <div className="container-fluid">
                                <br /><br />
                                <ul className="list-unstyled multi-steps">
                                    <ProgressBar key={this.props.rewardId + '-' + this.props.maxStep} maxStep={this.props.maxStep} rewardSteps={this.props.rewardSteps}></ProgressBar>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <Script 
                                customerName={this.props.customerName} 
                                customerEmail={this.props.customerEmail}
                                orderShipped={this.props.orderShipped}
                                isEmployee={true} 
                                script={this.props.maxStepScript} 
                            ></Script>
                        </div>
                            <div className="container p-1 ml-4 text-left">
                                <h6>
                                    <i className="fas fa-box-open mr-1"></i>
                                    <span style={{fontWeight: '700'}}>Order Status: </span>{this.props.orderStatus}
                                </h6>
                                <h6>
                                    <i className="fas fa-shipping-fast mr-1"></i>
                                    <span style={{fontWeight: '700'}}>Shipped Date: </span>{this.props.orderShipped}
                                </h6>
                                <h6>
                                    <i className="fas fa-map-marked-alt mr-1"></i>
                                    <span style={{fontWeight: '700'}}>Tracking Number: </span> {isNumber.test(this.props.orderTracking) ? <a href={'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber='+ this.props.orderTracking}>{this.props.orderTracking}</a> : this.props.orderTracking}
                                </h6>
                            </div>
                            <hr></hr>
                    </div>
                </div>
            )

    }
    
}