import React, { Component } from "react";

import "core-js/stable";
import axios from "axios";

import { CSSTransition } from "react-transition-group";

import "../Assets/Styles/CSS/Transitions.css";

class GenerateCodeCardBody extends Component {
  state = {
    showInvalidInput: false,
    showNotFoundInput: false,
  };

  generateCode = (e) => {
    e.preventDefault();
    //Show loading spinner and text
    document.getElementById("getCodeBtn").disabled = true;
    document.getElementById("btn-inner--text").innerHTML =
      '<div class="spinner-border spinner-border-sm mb-1 mr-1" role="status"><span class="sr-only">Loading...</span></div>';
    document.getElementById("btn-inner--icon").innerHTML = "Loading";

    //Toggle invalid messages
    if (this.state.showInvalidInput === true) {
      this.setState({ showInvalidInput: !this.state.showInvalidInput });
    } else if (this.state.showNotFoundInput === true) {
      this.setState({ showNotFoundInput: !this.state.showNotFoundInput });
    }

    //Check input validity (must be email or 9 digit account number)
    var inputText = document.getElementById("input-emailAddress").value;
    var trimmedInput = inputText.trim();
    var validEmail = RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
    var validAccount = RegExp("^[0-9]{9,12}$");
    var isValidInput =
      validEmail.test(trimmedInput) || validAccount.test(trimmedInput);

    if (isValidInput === true) {
      //If valid attempt email send
      var host =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port.length > 0 ? ":" + window.location.port : "") +
        window.location.pathname; //page to direct email link towards

      axios
        .post(
          "https://prod-58.westus.logic.azure.com:443/workflows/4a5a7a07e484481292324d03852fd187/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hs2bYOOOmoHyFCouSrjOd65bLMHul9CmMVtlcRkQGuA",
          {
            searchInput: trimmedInput,
            searchType:
              validEmail.test(trimmedInput) === true
                ? "email"
                : "accountNumber",
            host: host,
          }
        )
        .then((response) => {
          document
            .getElementById("getCodeBtn")
            .classList.remove("btn-customPrimary");
          document.getElementById("getCodeBtn").classList.add("btn-success");
          document.getElementById("btn-inner--text").innerHTML = "Code Sent!";
          document.getElementById("btn-inner--icon").innerHTML =
            '<i className="ml-2 fas fa-inbox"></i>';
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showNotFoundInput: !this.state.showNotFoundInput });
          document.getElementById("getCodeBtn").disabled = false;
          document.getElementById("btn-inner--text").innerHTML =
            "Send me a code";
          document.getElementById("btn-inner--icon").innerHTML =
            '<i className="ml-2 fas fa-inbox"></i>';
        });
    } else {
      //If invalid show error
      document.getElementById("getCodeBtn").disabled = false;
      this.setState({ showInvalidInput: true });
      document.getElementById("btn-inner--text").innerHTML = "Send me a code";
      document.getElementById("btn-inner--icon").innerHTML =
        '<i className="ml-2 fas fa-inbox"></i>';
    }
  };

  render() {
    return (
      <div>
        <div className="mb-3">
          <h6 className="h3">Generate An Access Code</h6>
          <p
            style={{ lineHeight: 1.3, fontSize: "14.5px" }}
            className="text-muted font-weight-light mb-0"
          >
            Enter your account email address or numeric Viasat account number
            and we will send you a temporary access code.
          </p>
        </div>
        <form onSubmit={this.generateCode}>
          <div className="form-group mb-2">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <label className="form-control-label">
                  Email Address or Account Number
                </label>
              </div>
              <div className="mb-2"></div>
            </div>
            <div className="input-group input-group-merge">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-envelope-square"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control text-left"
                id="input-emailAddress"
                placeholder="Email or Account Number"
                required
              />
            </div>
          </div>
          <CSSTransition
            in={this.state.showInvalidInput}
            unmountOnExit
            classNames="fade"
            timeout={300}
          >
            <p className="text-danger mb-0">Invalid Account Number or Email</p>
          </CSSTransition>
          <CSSTransition
            in={this.state.showNotFoundInput}
            unmountOnExit
            classNames="fade"
            timeout={300}
          >
            <p class="text-danger mb-0">We were unable find your account</p>
          </CSSTransition>
          <div className="mt-3">
            <button
              id="getCodeBtn"
              type="submit"
              className="btn btn-md btn-customPrimary btn-icon"
            >
              <span id="btn-inner--text" className="btn-inner--text">
                Send me a code
              </span>
              <span id="btn-inner--icon" className="btn-inner--icon">
                <i className="ml-2 fas fa-inbox"></i>
              </span>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default GenerateCodeCardBody;
